import React, { useState, useRef } from "react";
import BackArrow from "../../../../../../../assets/img/back-arrow.inline.svg";
import Button from '../../../../general/Button'
import { validateEmail, validateWebsite } from "../../../../../../../util/util";
import GeneralDropdown from "../../../../general/GeneralDropdown";
import countryList from '../../json/nigeria.json'
import PhoneNumberInput from "../../../../general/phoneNumberInput";
import { FormLayoutWrapper, FormInnerWrapper, InnerWrapper, ModalWrapper, BusinessFormWrap } from "./formStyle";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, navigate } from "gatsby";
import SalesSuccessModal from "../../../../../../modals/salesSuccessModal";

const apiList = [
    {
      "name": "Receive funds via Pay ID or Transfer",
      "id": 1
    },
    {
      "name": "Send funds via Transfer",
      "id": 2
    },
    {
      "name": "Bill payments",
      "id": 3
    },
    {
      "name": "Gift cards",
      "id": 4
    },
    {
      "name": "Instant settlement",
      "id": 5
    },
    {
      "name": "Account Management including Webhooks, Balance Enquiries and Transaction History",
      "id": 6
    }
]

const ApiForm = () => {
    const reCaptchaRef = useRef()

    const [inputValues, setInputValues] = useState({
        name: '',
        phoneNo: '',
        email: '',
        website: '',
        product: '',

        showEmailErrMsg: false,
        showWebErrMsg: false,
    });

    const [modalObj, setModalObj] = useState({
        showModal: false,
        modalMessage: ""
    });

    const [showErrModal, setShowErrModal] = useState(false);

    const [productInfo, setProductInfo] = useState({
        product: {
            info: "",
        }
    });
    
    const [phoneDataInfo, setPhoneDataInfo] = useState({
        dial_code: "+234",
        phoneLength: 10
    })

    const [validationErrObj, setValidationErrObj] = useState({
        emailErrMsg: "",
        webErrMsg: "",
    });

    const [loading, setLoading] = useState(false);

    const handleUserInput = (event) => {

        const { value, name } = event.target;

        setInputValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    function chooseProduct(apiItem) {
        setProductInfo({
            ...productInfo,
            product: {
                info: apiItem,
            }
        });

        setInputValues((prevState) => ({
            ...prevState,
            ["product"]: apiItem.name
        }))
    }

    function handleEmailValidation(email) {
        const error = validateEmail(email);

        setInputValues((prevState) => ({
            ...prevState,
            showEmailErrMsg: error ? true : false,
        }));

        setValidationErrObj((prev) => ({
            ...prev,
            emailErrMsg: error
        }));
        return error ? true : false;

    }

    function handleUrlValidation(urlLink) {
        const error = validateWebsite(urlLink);

        setInputValues((prevState) => ({
            ...prevState,
            showWebErrMsg: error ? true : false,
        }));

        setValidationErrObj((prev) => ({
            ...prev,
            webErrMsg: error
        }));
        return error ? true : false;

    }

    const checkEmailValue = () => {
        const hasError = handleEmailValidation(inputValues.email);
        if (hasError) return;
    }

    const checkUrlValue = () => {
        const hasError = inputValues.website.length > 0 ? handleUrlValidation(inputValues.website) : false;
        if (hasError) return;
    }

    const resetWebErr = () => {
        setValidationErrObj((prev) => ({
            ...prev,
            webErrMsg: ""
        }));
    }

    const handleSubmission = async (e) => {
        e.preventDefault();
        setLoading(true);

        const token = await reCaptchaRef.current.executeAsync();

        const payload = {
            companyName: inputValues.name,
            contactPersonPhone: `${phoneDataInfo?.dial_code}${inputValues.phoneNo}`,
            contactPersonEmail: inputValues.email,
            companyWebsite: inputValues.website,
            productOption: inputValues.product,
            captchaToken: token
        };

        const WebAppURL = process.env.GATSBY_ENV === "Prod" ? `${process.env.WAITLIST_API_PROD}/api/Credit/acknowledge-product-application` : `${process.env.WAITLIST_API}/api/Credit/acknowledge-product-application`;
        try {
            const response = await fetch(WebAppURL, {
                headers: {
                    Token: process.env.GATSBY_ENV === "Prod" ? `${process.env.WAITLIST_TOKEN_PROD}` : `${process.env.WAITLIST_TOKEN}`,
                    "Content-Type": 'application/json'
                },
                method: "POST",
                body: JSON.stringify(payload),
            })

            const data = await response.json();

            if (data.isSuccessful) {
                setLoading(false);

                setModalObj({
                    ...modalObj,
                    showModal: !modalObj.showModal,
                    modalMessage: `Your request has been sent to our sales team.`
                })
            }
        } catch (error) {
            console.log(error, 'error')
            setLoading(false);            
            setShowErrModal(true);
        }
    };

    const closeForm = () => {
        navigate(`/en-ng/business/open-api/`)

        setModalObj({
            ...modalObj,
            showModal: false,
            modalMessage: ""
        })
    }

    const closeErrModal = () => {
        navigate(`/en-ng/business/open-api/`)
        setShowErrModal(false);
    }
    return (
        <BusinessFormWrap>
            <FormLayoutWrapper>
                <InnerWrapper height={modalObj?.showModal ? '607px' : 'auto'} showModal={modalObj?.showModal}>
                    <Link to="/en-ng/business/open-api/">
                        <button
                            type="button"
                            className="back-to color-black"
                        >
                            <span className="backArrow">
                                <BackArrow />
                            </span>
                            Back
                        </button>
                    </Link>

                    {!modalObj?.showModal &&
                        <FormInnerWrapper>
                            <h2 className="kuda-page--title color-black text-center">Request for API Product</h2>

                            <div className="card-group">
                                <label className="card-label color-black">Company Name </label>
                                <div className="add-amount">
                                    <input
                                        className="amount-input normal-input"
                                        placeholder="Company's Name"
                                        type="text"
                                        name="name"
                                        value={inputValues?.name}
                                        onChange={handleUserInput}
                                        maxLength={50}
                                        minLength={1}
                                    />
                                </div>
                            </div>

                            <div className="card-group mr-3 mt-25">
                                <label className="card-label color-black">Contact Person’s Phone Number </label>
                                <div>
                                    <PhoneNumberInput
                                        type="tel"
                                        name="phoneNo"
                                        placeholder="800 000 0000"
                                        allowNegative={false}
                                        onChange={handleUserInput}
                                        // onSelected={onCodeChange}
                                        decimalSeparator={false}
                                        value={inputValues.phoneNo}
                                        allowLeadingZeros
                                        maxLength={phoneDataInfo?.phoneLength}
                                        list={countryList}
                                    />

                                </div>
                            </div>

                            <div className="card-group">
                                <label className="card-label color-black">Contact Person’s Email </label>
                                <div className="add-amount">
                                    <input
                                        className="amount-input normal-input"
                                        placeholder="example@gmail.com"
                                        type="email"
                                        name="email"
                                        value={inputValues?.email}
                                        onChange={handleUserInput}
                                        minLength={10}
                                        onBlur={checkEmailValue}
                                    />
                                </div>

                                {inputValues?.showEmailErrMsg && (
                                    <p className="color-negative text-sm text-semi-bold mt-2">
                                        {validationErrObj?.emailErrMsg}
                                    </p>
                                )}
                            </div>

                            <div className="card-group">
                                <label className="card-label color-black">Company Website</label>
                                <div className="add-amount">
                                    <input
                                        className="amount-input normal-input"
                                        placeholder="websitename.com"
                                        type="text"
                                        name="website"
                                        value={inputValues?.website}
                                        onChange={handleUserInput}
                                        onBlur={inputValues?.website ? () => {checkUrlValue(inputValues.website)} : resetWebErr}
                                    />
                                </div>

                                {inputValues?.showWebErrMsg && (
                                    <p className="color-negative text-sm text-semi-bold mt-2">
                                        {validationErrObj?.webErrMsg}
                                    </p>
                                )}
                            </div>

                            <div className="card-group">
                                <label className="card-label color-black">API product needed</label>
                                <div className="add-amount">
                                    <GeneralDropdown
                                        name={"product"}
                                        list={apiList}
                                        onSelect={chooseProduct}
                                        type="onboarding"
                                        user={productInfo}
                                        headerTitle={
                                            inputValues?.product
                                        }
                                        placeholder={"Search API Product"}
                                    />
                                </div>
                            </div>

                            <div className="">
                                <Button
                                    text="Send Request"
                                    width='100%'
                                    background="#000000"
                                    isSubmitting={loading}
                                    disabled={
                                        inputValues?.name === "" ||
                                        inputValues?.phoneNo === "" ||
                                        inputValues?.email === "" ||
                                        inputValues?.product === "" ||
                                        validationErrObj?.webErrMsg !== "" || validationErrObj?.emailErrMsg !== ""
                                    }
                                    onButtonClick={(e) => handleSubmission(e)}
                                />

                            </div>

                            <ReCAPTCHA
                                sitekey={process.env.RECAPTCHA_SITEKEY}
                                size="invisible"
                                ref={reCaptchaRef}
                            />
                            <p style={{
                                fontSize: '11px',
                                fontWeight: 400,
                                lineHeight: '15px',
                                marginTop: '8px'
                            }}>Never share sensitive information (credit card numbers, social security numbers, passwords) through this form.</p>
                        </FormInnerWrapper>
                    }

                    {modalObj?.showModal && (
                        <ModalWrapper>
                            <SalesSuccessModal message="Sent" doSomethingElse={closeForm}>
                                <div className="password-set--message">
                                    <p className="text-center color-black">{modalObj?.modalMessage}</p>
                                </div>
                            </SalesSuccessModal>
                        </ModalWrapper>
                    )}

                    {showErrModal && (
                        <ModalWrapper>
                            <div className="modal modal-sendMoney small-modal modal-backdrop fadeIn animated">
                                <div className="modal-content fadeInUp animated">
                                    <div className="kuda-modal--content">
                                        <div className="message-response error-message withdrawal-response">
                                            <p className="color-black"> Sorry! Something went wrong and we'll need you to retry your request.</p>
                                        </div>
                                        <div className="flex justify-content-center mt-5 inactivity-buttons ">
                                            <div className="addAction kuda-cta kuda-cta-lg" onClick={closeErrModal}>Okay</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalWrapper>
                    )}

                </InnerWrapper>
            </FormLayoutWrapper>
        </BusinessFormWrap>
    )

}

export default ApiForm;