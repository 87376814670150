import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import ApiForm from "../../../components/body/pages/en-ng/business/components/forms/apiForm"

const ApiFormPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/api-application-form/"}
      title="Open API Form | Apply to connect your business with our Open APIs | Kuda Business"
      description="Apply to unlock a world of possibilities with Kuda's open API. Seamlessly integrate innovative banking solutions into your applications"
    />
    <ApiForm />
  </Layout>
)

export default ApiFormPage
